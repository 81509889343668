import React, { useState, useEffect } from "react";
import { Menu, Layout, Avatar, Space } from "antd";
import { Link } from "react-router-dom";

import { useWindowWidth } from "@react-hook/window-size";

import { useSelector } from "react-redux";
import { AdminNavdata } from "../../data/data";
import "./../../App.css";

const { SubMenu } = Menu;
const { Sider } = Layout;

const AdminNav = () => {
  const { user } = useSelector((state) => state.user);

  // state
  const [collapsed, setCollapsed] = useState(false);

  // hooks
  const onlyWidth = useWindowWidth();

  useEffect(() => {
    if (onlyWidth < 800) {
      setCollapsed(true);
    } else if (onlyWidth > 800) {
      setCollapsed(false);
    }
  }, [onlyWidth < 800]);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
    >
      <Menu
        defaultOpenKeys={["1", "2", "3", "4", "5", "6", "7", "8"]}
        mode="inline"
        inlineCollapsed={collapsed}
        style={{
          paddingTop: "50px",
          fontSize: 16,
          color: "#102fe0",
          width: `${collapsed ? "auto" : "250px"}`,
        }}
        theme="light"
        inlineIndent="30"
      >
        {AdminNavdata.map((m) => (
          <SubMenu key={m.id} icon={m?.icon} title={m.title}>
            {m?.Menu_item?.map((mm) => (
              <Menu.Item key={mm.id}>
                <Link to={mm.link}>
                  {mm.icon}
                  <a className="pl-2">{mm.label}</a>
                </Link>
              </Menu.Item>
            ))}
          </SubMenu>
        ))}
      </Menu>
    </Sider>
  );
};

export default AdminNav;
