import React, { useState, useEffect } from "react";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Adminlayout from "../../../components/layout/AdminLayout";
import { readMachin, editMachin } from "../../../functions/machin";
import FileUpload from "../../../components/forms/FileUpload";
// import FileUpload from "./FileUpload";

const MachinUpdate = () => {
  const { user } = useSelector((state) => state.user);
  console.log(user);

  const { id } = useParams();
  const navigate = useNavigate();

  const [values, setValues] = useState({
    name: "",
    description: "",
    images: [],
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    readMachin(id)
      .then((res) => {
        setValues({ ...values, ...res.data });
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    editMachin(user.token, id, values)
      .then((res) => {
        setLoading(false);
        toast.success(`"${res.data.name}" is updated`);
        navigate("/admin/machin");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(err.response.data.err);
      });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <Adminlayout>
      <div className="pt-20" />
      <Breadcrumb className="font-Phetsarath font-semibold">
        <Breadcrumb.Item href="/admin/machin">
          <HomeOutlined style={{ fontSize: "18px", color: "#17cf73" }} />
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/admin/machin">
          <span>ເຄື່ອງມືການແພດ</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>ແກ້ໄຂເຄື່ອງມືການແພດ</Breadcrumb.Item>
      </Breadcrumb>
      <div className="container pt-6 mx-auto font-Phetsarath">
        <div className="flex flex-wrap">
          <div className=" w-full pb-6 md:pb-0 md:pr-6">
            <form
              onSubmit={handleSubmit}
              className="container mx-auto bg-white shadow rounded p-5"
            >
              <div className="mt-1 w-full px-2 sm:px-6">
                <lebel>ຊື່ເຄື່ອງມືການແພດ</lebel>
                <div className="flex flex-col mt-1">
                  <input
                    required
                    // disabled={true}
                    value={values?.name}
                    onChange={handleChange}
                    name="name"
                    className="h-8 px-2 w-full rounded mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 dark:focus:border-indigo-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 border-gray-300 border shadow"
                    type="text"
                    placeholder="ຊື່ເຄື່ອງມືການແພດ"
                  />
                </div>
              </div>
              <div className="mt-1 w-full px-2 sm:px-6">
                <lebel>ຄຳອະທິບາຍ</lebel>
                <div className="flex flex-col mt-1">
                  <input
                    required
                    onChange={handleChange}
                    value={values?.description}
                    name="description"
                    className="h-8 px-2 w-full rounded mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 dark:focus:border-indigo-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 border-gray-300 border shadow"
                    type="text"
                    placeholder="ຊື່ເຄື່ອງມືການແພດ"
                  />
                </div>
              </div>
              <div className="mt-1 w-full px-2 sm:px-6">
                <lebel>ຮູບພາບ</lebel>

                <FileUpload
                  loading={loading}
                  setLoading={setLoading}
                  values={values}
                  setValues={setValues}
                />
              </div>

              <div className="px-2 sm:mb-16 sm:px-6 text-center">
                <button
                  type="submit"
                  className="focus:outline-none w-full sm:w-auto bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 rounded text-white px-8 py-3 text-sm mt-6"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Adminlayout>
  );
};

export default MachinUpdate;
